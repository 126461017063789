<template>
    <v-app>
      <dashboard-core-view />
  
      <dashboard-core-settings />
  
     
  
  
      <v-container
        fluid
        tag="section">
  
    
          <v-card-text>
            <v-form ref="form" style="margin-top:20px"     lazy-validation> 
  
            <v-container>
  
                <v-row>
                  <v-btn
                  color="green darken-2" class="white--text" large @click="whats" block>
                    <b>Teste Abrir Whatsapp-82982121700</b>
                      <v-icon right dark> mdi-call</v-icon>          
                </v-btn>
              </v-row>
              <br/>
                <v-row>

                <a href="https://api.whatsapp.com/send/?phone=55082982121700"  target="_blank" >teste whatsssssss</a>

                </v-row>
                <br/>
                <br/>


                <v-row>
                  <v-btn
                  color="green darken-2" class="white--text" large @click="whats2" block>
                    <b>Teste Abrir Whatsapp-82982121700</b>
                      <v-icon right dark> mdi-call</v-icon>          
                  </v-btn>

  
                </v-row>
  

                
                
              
              
            </v-container>
             </v-form>
  
          </v-card-text>
  
        <div style="text-align:center;">
          <img src="./../../assets/img_log_med_branca.png" style="width:50%; max-width:140px" />
          </div>
        
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            Rua José de Alencar, nº 121 <br/> &copy; 2021 Previparq.
          </div>
      </v-container>
  
     </v-app> 
    </template>
    <script>
    
      export default {
        name: 'LecuponFale',
    
        mounted(){

    

        //const urlParams = new URLSearchParams(window.location.search);
        //this.mensagem.email = urlParams.get("email");    
        },
  
        data () {
          return {
            url: '',
             alert_msg: '',
             email: '',
             msg_retorno: '',
             mensagem:{
              nome: '',
              telefone: '',
              email: '',
              msg: '',
  
             }
  
            }
        },
    
       
        methods: {
         
         clear () {
             this.$refs.form.reset();
           },
  
           enviar(){
            // alert()
           },

           whats(){
            let url = 'https://api.whatsapp.com/send?phone=55082982121700&text=Acessei+o+site+e+quero+tirar+uma+d%C3%BAvida'
            window.open(url);

          },


           whats2(){
            let url = launch('https://api.whatsapp.com/send/?phone=55082982121700')
            window.open(url);

          }


          
           
       },
  
      }
    </script>

  
  
  